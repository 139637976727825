import { inMemoryAuth } from '../../in-memory-auth';

export const authValidate = (isStaff, permissions) => {
  if (isStaff && !inMemoryAuth?.user?.isStaff) {
    return false;
  }

  if (permissions.length > 0) {
    for (let i = 0; i < permissions.length; i += 1) {
      if (!inMemoryAuth.user.userPermissions.includes(permissions[i])) {
        return false;
      }
    }
  }

  return true;
};
