import React from 'react';
import { Link } from 'gatsby';
import { Badge, Button } from 'antd';
import { useReactiveVar } from '@apollo/client';
import { AiOutlineSetting } from 'react-icons/all';
import css from './menu.module.less';
import { PrivateComponent } from '../../../../auth/authorization/private-component';
import { inMemoryAuth } from '../../../../auth/in-memory-auth';
import { actWishVariants } from '../../../../../apollo/cache';

export const NavBarMenu = () => {
  const wishlistVariants = useReactiveVar(actWishVariants);

  const authLink = inMemoryAuth.token
    ? '/user/account'
    : '/user/auth/';

  return (
    <nav>
      <ul className={css.menu}>
        <li>
          <Link to="/support" className={css.menuItem}>
            Support
          </Link>
        </li>
        <li>
          <Link to="/search" className={css.menuItem}>
            Search
          </Link>
        </li>
        <li>
          <Link to="/user/favourites/" className={css.menuItem}>
            <Badge count={wishlistVariants.length}>Favourites</Badge>
          </Link>
        </li>
        <li>
          <Link
            to={authLink}
            className={css.menuItem}
          >
            {inMemoryAuth.token ? 'Account' : 'Sign in'}
          </Link>
        </li>
        <PrivateComponent isStaff>
          <li>
            <a href={process.env.GATSBY_SALEOR_DASHBOARD_URL} className={css.menuItem}>
              <Button size="large" icon={<AiOutlineSetting />} />
            </a>
          </li>
        </PrivateComponent>
      </ul>
    </nav>
  );
};
