import React from 'react';
import {
  Col, Divider, Row, Space, Typography,
} from 'antd';
import { Link } from 'gatsby';
import {
  FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube,
} from 'react-icons/all';
import css from './footer.module.less';
import { useShop } from '../../../../graphql/static-hooks/useShop';

const {
  Title,
  Text,
} = Typography;

export const Footer = () => {
  const shop = useShop();
  return (
    <footer className={css.footer}>
      <Row>
        <Col flex={1}>
          <Link to="/">
            <Title level={2}>{shop.name}</Title>
          </Link>
        </Col>
        <Col>
          <Space style={{ fontSize: '22px' }}>
            <a href={shop.facebook}><FaFacebook /></a>
            <a href={shop.linkedin}><FaLinkedinIn /></a>
            <a href={shop.youtube}><FaYoutube /></a>
            <a href={shop.instagram}><FaInstagram /></a>
            <a href={shop.twitter}><FaTwitter /></a>
          </Space>
        </Col>
      </Row>

      <Divider />
      <Row gutter={32} justify="space-between">
        <Col xs={12} md={6}>
          <Title level={4}>{shop.description}</Title>
          <Link to="/sitemap.xml"><Title level={5}>Site Map</Title></Link>
        </Col>
        <Col xs={12} md={6}>
          <Title level={4}><Link to="/search">Search</Link></Title>
          <Title level={4}><Link to="/collections">Collections</Link></Title>
          <Title level={4}><a href={process.env.GATSBY_BLOG_URL}>Blog</a></Title>
        </Col>
        <Col xs={12} md={6}>
          <Title level={4}>
            <Link to="/about">
              {`Meet ${shop.name}`}
            </Link>
          </Title>
          {/* <Title level={4}><Link to="/about/team">Team</Link></Title> */}
          <Title level={4}><Link to="/about/CSR">CSR</Link></Title>
        </Col>
        <Col xs={12} md={6}>
          {/* <Title level={4}><Link to="/about/FAQ">FAQ</Link></Title> */}
          <Title level={4}><Link to="/about/terms">General T&C</Link></Title>
          <Title level={4}><Link to="/about/privacy">Data & Privacy</Link></Title>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col>
          <Space direction="vertical">
            <Text>
              ©
              {new Date().getFullYear()}
              {' '}
              {shop.name}
              {' '}
              is a brand of
              {' '}
              {' '}
              {shop.companyAddress?.companyName}
            </Text>
            <Text type="secondary">
              {shop.name}
              {' '}
              has no legal or commercial connection with events featured on
              {' '}
              {shop.domain.url}
              . The names of such events are trademarks and the
              property of their respective owners.
            </Text>
          </Space>
        </Col>
      </Row>
    </footer>
  );
};
