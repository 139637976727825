import { graphql, useStaticQuery } from 'gatsby';

const STATIC_QUERY_SHOP = graphql`
    query Shop {
        site {
            siteMetadata {
                title
                description
                siteUrl
                contactEmail
                twitter
                instagram
                youtube
                facebook
                linkedin
            }
        }
        saleor {
            shop {
                name
                headerText
                domain {
                    host
                    url
                }
                description
                permissions {
                    code
                    name
                }
                countries {
                    code
                    country
                }
                phonePrefixes
                companyAddress {
                    companyName
                    streetAddress1
                    streetAddress2
                    city
                    cityArea
                    postalCode
                    country {
                        code
                        country
                    }
                    countryArea
                    phone
                }
            }
        }
    }
`;

export const useShop = () => {
  const {
    saleor: { shop },
    site: { siteMetadata },
  } = useStaticQuery(STATIC_QUERY_SHOP);
  return {
    name: shop.name || siteMetadata.title,
    headerText: shop.headerText,
    domain: {
      host: shop.host || siteMetadata.siteUrl,
      url: shop.url || siteMetadata.siteUrl,
    },
    twitter: siteMetadata.twitter,
    instagram: siteMetadata.instagram,
    youtube: siteMetadata.youtube,
    facebook: siteMetadata.facebook,
    linkedin: siteMetadata.linkedin,
    description: shop.description || siteMetadata.description,
    permissions: shop.permissions,
    countries: shop.countries,
    phonePrefixes: shop.phonePrefixes,
    defaultMailSenderAddress: siteMetadata.contactEmail,
    companyAddress: shop.companyAddress,
  };
};
