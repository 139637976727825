import React from 'react';
import PropTypes from 'prop-types';
import { authValidate } from './utils/auth-validate';

export const PrivateComponent = ({
  children,
  isStaff,
  permissions,
}) => (authValidate(isStaff, permissions) ? <>{children}</> : null);

PrivateComponent.defaultProps = {
  permissions: [],
  isStaff: false,
};

PrivateComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.oneOf([
      'MANAGE_USERS',
      'MANAGE_STAFF',
      'MANAGE_SERVICE_ACCOUNTS',
      'MANAGE_APPS',
      'MANAGE_DISCOUNTS',
      'MANAGE_PLUGINS',
      'MANAGE_GIFT_CARD',
      'MANAGE_MENUS',
      'MANAGE_ORDERS',
      'MANAGE_PAGES',
      'MANAGE_PRODUCTS',
      'MANAGE_PRODUCT_TYPES_AND_ATTRIBUTES',
      'MANAGE_SHIPPING',
      'MANAGE_SETTINGS',
      'MANAGE_TRANSLATIONS',
      'MANAGE_CHECKOUTS',
    ]),
  ),
  isStaff: PropTypes.bool,
};
