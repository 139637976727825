import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import { Header } from './header';
import { Footer } from './footer/footer';
import css from './base-layout.module.less';
import { useShop } from '../../../graphql/static-hooks/useShop';
import { actWishVariants } from '../../../apollo/cache';
import isBrowser from '../../../apollo/is-browser';

export const restoreReactiveVar = (reactiveVariable, localStorageKey) => {
  if (isBrowser()) {
    const ls = JSON.parse(localStorage.getItem(localStorageKey));
    if (ls) {
      reactiveVariable(ls);
    }
  }
};

export const BaseLayout = ({ children }) => {
  const shop = useShop();

  useEffect(() => {
    restoreReactiveVar(actWishVariants, 'actWishVariants');
  }, []);

  return (
    <IconContext.Provider value={{
      className: 'anticon',
      size: '1em',
    }}
    >
      <Header siteTitle={shop.name} />
      <main className={css.main}>{children}</main>
      <Footer />
    </IconContext.Provider>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
