import { Link } from 'gatsby';
import React from 'react';
import { Col, Row } from 'antd';
import Logo from '../../../../images/logo.svg';
import { NavBarMenu } from './menu';
import css from './header.module.less';
import { useShop } from '../../../../graphql/static-hooks/useShop';

export const Header = () => {
  const shop = useShop();

  return (
    <header className={css.header}>
      <Row gutter={24} type="flex" justify="space-between" align="middle">
        <Col flex="0 1 180px" className={css.logo}>
          <Link to="/">
            <img
              src={Logo}
              width={156}
              alt={`${shop.name} Logo`}
            />
          </Link>
        </Col>
        <Col flex="0 1 500px">
          <NavBarMenu />
        </Col>
      </Row>
    </header>
  );
};
